import React from 'react'
import { graphql } from 'gatsby'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import ContactBanner from '../../components/Banner/ContactBanner'
import Hero from '../../components/Hero'
import { Section, SectionFullWidth } from '../../styles/common/Common'
import { BreadcrumbWrapper, Item, ItemWrapper } from '../../styles/common/Element'
import BlockContent from '../../components/BlockContent'
import ImageZoom from '../../components/ImageZoom'
import SEO from '../../components/SEO'

export default function ManuelleLymphdrainagePage({ pageContext, data }) {
  const {
    breadcrumb: { crumbs },
  } = pageContext
  return (
    <>
      <SEO
        title={"Manuelle Lymphdrainage"}
      />
      <Hero slug={data.page.slug.current} small>
        <h1 className="headline">{data.page.pageName}</h1>
        <h2>{data.page.subHeadline}</h2>
      </Hero>
      <SectionFullWidth>
        <ItemWrapper fullWidth>
          <Item isImage>
            <ImageZoom image={data.page.contentImage.asset.gatsbyImageData} alt={data.page.pageName} />
          </Item>
          <Item>
            <BreadcrumbWrapper>
              <Breadcrumb crumbs={crumbs} crumbLabel={data.page.pageName} />
            </BreadcrumbWrapper>
            <BlockContent blocks={data.page._rawText} />
          </Item>
        </ItemWrapper>
      </SectionFullWidth>
      <ContactBanner />
    </>
  )
}


export const query = graphql`
  query ManuelleLymphdrainagePageQuery {
    page: sanityPageContent(slug: {current: {eq: "manuelle-lymphdrainage"}}) {
      id
      pageName
      slug {
        current
      }
      subHeadline
      _rawText(resolveReferences: {maxDepth: 10})
      contentImage {
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }

    }
  }
`;
